<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
    <div class="feealer">
      <div class="wamders" @click="decoler()"></div>
      <div class="fillalers">
        <div class="filltop">
          <span>{{$t('header.Feedback')}}</span><span @click="decoler()">X</span>
        </div>
        <div class="fillabout">
          <div class="taglis">
            <div style="padding-top:10px">{{$t('footer.Feedbackdes')}}</div>
            <div>
              <el-input
                type="textarea"
                  placeholder="请输入内容"
                  v-model="textarea"
                  show-word-limit
                  :autosize="{ minRows: 4, maxRows: 4}"
                  resize="none"
                  style="margin-left:10px;width:260px"
              >
              </el-input>
            </div>
          </div>
          <div class="taglies">
            <div>{{$t('footer.Upload')}}</div>
            <div style="margin-left:10px;">
              <el-upload
                action="#"
                list-type="picture-card"
                :auto-upload="false"
                :limit="2">
               <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                 <img
                 class="el-upload-list__item-thumbnail"
                 :src="file.url" alt=""
                 >
                 <span class="el-upload-list__item-actions">
                  <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                  >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
</el-upload>
<el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
            </div>
          </div>
        </div>
        <div class="fillbtn" style="margin-top:15px;">
          <div class="form-btn-section">
            <el-button color="#28C76F" size="small" @click="decoler" >{{$t('button.Cancel')}}</el-button>
            <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" >{{$t('button.Save')}}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'

export default {
  data() {
    return {
      textarea:'',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    }
  },
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    onSubmit(){},
    decoler(){
      document.querySelector(".feealer").style="display:none"
    }
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/themes/bordered-layout.scss";
.el-upload--picture-card{
    width: 120px;
    height: 120px;
}
.taglis{
  display: flex;
  margin-left: 20%;
  margin-top: 10px;
  height: 100px;
}
.taglies{
  display: flex;
  margin-left: 20%;
  margin-top: 10px;
  height: 150px;
}
.taglis>div{
  height: 100px;
}
.cancel{
  border-radius: 3px;
  border: 1px solid #DCDFE6;
  padding: 3px 6px;
  background: rgba(255, 255, 255);
}
button.el-button.el-button--default.el-button--small,button.el-button.el-button--success.el-button--small{
  padding:10px 22px;
}
.feealer{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2001;
    display: none;
  }
  .wamders{
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0,.4);
  }
  .fillbtn{
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .fillbtn>div{
    width: 100%;
  }
.fillalers{
  width: 600px;
  height: 400px;
  background: white;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  .filltop{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    height: 45px;
    line-height: 45px;
  }

}
</style>
