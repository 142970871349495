<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <div class="allstore">
        <div style="width:100%" @click="selectStore"> All Store</div>
        <div class="allsect"></div>
      </div> -->
      <template v-if="userType ===203 || userType ===204">
        <div style="width:215px;cursor: pointer;">
          <el-input
            placeholder="All Store"
            suffix-icon="el-icon-arrow-down"
            readonly
            @focus="selectStore"
            size="small"
            v-model="storeName">
          </el-input>
        </div>
        <!-- <el-select v-model="storeName"  @focus="selectStore" placeholder="All Store" readonly size="small"></el-select> -->
      </template>
      
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <!-- <search-bar /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>

    <!-- 选择门店 -->
    <selectStore ref="selectStore"  @confirmSelect="handleSelectStore"></selectStore>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
// import {getstoreid} from '../../../../libs/api/axioslist.js'

import selectStore from '@/components/select-store/index'
import {commonStore} from '@/store/global-select-store'

export default {
  data() {
    return {
      commonStore,
      secstore:[],
      storeId: localStorage.getItem("storeId") || '',
      storeName: localStorage.getItem("storeName") || '',
      userType:''
    }
  },
  created(){
    let userData = JSON.parse(localStorage.getItem('userData'))
    this.userType = userData.userType
  },
  mounted(){
   },
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    Locale,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    selectStore
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    // 选择门店弹窗
    selectStore(){
      this.$refs.selectStore.open(this.storeId)
    },
    handleSelectStore(storeId,storeObj){
      console.log(storeId,storeObj)
      if(this.storeId !== storeId){
        localStorage.setItem("storeId",storeId);
        localStorage.setItem("storeName",storeObj.storeName);
        localStorage.setItem("storeObj",JSON.stringify(storeObj));
        this.storeId = storeId
        this.storeName= storeObj.storeName
        commonStore.setStoreId(storeId)
      }
      
    }
  }
}
</script>
<style>
  .allstore{
    border: 1px solid #DDD;
    /* padding: 3px 8px; */
    width: 300px;
    height: 38px;
    line-height: 38px;
    padding-left: 15px;
    border-radius: 4px;
    background: #fff;
    color: #AAAAAA;
    position: relative;
    /* width: 140px; */
  }
  .dvv{
    display: flex;
    justify-content: space-between;
    color: black;
  }
  .allsect{
    background: #f8aaff;
    width: 140px;
    height: 800px;
    position: absolute;
    left: 0;
    top: 30px;
    overflow: hidden;
    display: none;
  }
  .sctel{
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: black;
  }
  [dir] .header-navbar.floating-nav{
    margin:1.2rem;
  }
  [dir] .header-navbar.navbar-shadow {
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 5%);
}
</style>
